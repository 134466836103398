import React from 'react';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 font-sans">
      <header className="container mx-auto px-4 py-6">
        <nav className="flex justify-between items-center">
          <a href="/" className="text-xl md:text-2xl font-bold">Pod Nebula</a>
          <a href="/" className="text-sm md:text-base text-purple-400 hover:text-purple-300 transition-colors duration-300">
            Home
          </a>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-8 md:py-16 text-center">
        <h1 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8">About Pod Nebula</h1>
        <div className="max-w-2xl mx-auto text-lg md:text-xl leading-relaxed">
          <p className="mb-4">
            We started by winning the first AI Tinkerers Hackathon in Austin Texas.
          </p>
          <p className="mb-4">
            We're still in stealth mode. Connect with us at{' '}
            <a href="mailto:info@podnebula.com" className="text-purple-400 hover:text-purple-300 transition-colors duration-300">
              info@podnebula.com
            </a>
          </p>
        </div>
      </main>

      <footer className="container mx-auto px-4 py-6 text-center text-gray-400 text-sm md:text-base">
        <p>&copy; 2024 Pod Nebula. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default AboutPage;
