import React from 'react';
import { Radio, Search, Star } from 'lucide-react';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 font-sans">
      <header className="container mx-auto px-4 py-6">
        <nav className="flex justify-between items-center">
          <div className="text-xl md:text-2xl font-bold">Pod Nebula</div>
          <a href="/about" className="text-sm md:text-base text-purple-400 hover:text-purple-300 transition-colors duration-300">
            About
          </a>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-8 md:py-16 text-center">
        <h1 className="text-3xl md:text-5xl font-bold mb-4 md:mb-6">Discover Your Next Favorite Podcast</h1>
        <p className="text-lg md:text-xl mb-8 md:mb-12 max-w-2xl mx-auto">
          A new podcast discovery experience powered by large language models.
          Explore rich connections between Podcasts, People, and Ideas.
        </p>
        <div className="flex flex-col md:flex-row justify-center md:space-x-6 space-y-6 md:space-y-0 mb-12 md:mb-16">
        <FeatureCard 
          icon={<Search className="w-10 h-10 md:w-12 md:h-12 text-purple-400" />}
          title="Rich Discovery"
          description="Find podcasts tailored to your interests"
        />
        <FeatureCard 
          icon={<Star className="w-10 h-10 md:w-12 md:h-12 text-purple-400" />}
          title="Primary Source"
          description="Hear from people in their own words"
        />
        <FeatureCard 
          icon={<Radio className="w-10 h-10 md:w-12 md:h-12 text-purple-400" />}
          title="Insights"
          description="See connections between ideas across podcasts"
        />
      </div>
        <div className="bg-gray-800 p-6 md:p-8 rounded-lg max-w-md mx-auto">
          <h2 className="text-xl md:text-2xl font-bold mb-3 md:mb-4">Be the First to Know</h2>
          <p className="mb-4 md:mb-6">Join our waitlist and get early access when we launch!</p>
          <a 
            href="https://forms.gle/gqTEq5SwVGmMudFdA"
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-block bg-purple-600 hover:bg-purple-700 px-6 py-3 rounded-md transition-colors duration-300 text-base md:text-lg font-semibold"
          >
            Join Waitlist
          </a>
        </div>
      </main>

      <footer className="container mx-auto px-4 py-6 text-center text-gray-400 text-sm md:text-base">
        <p>&copy; 2024 Pod Nebula. All rights reserved.</p>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg flex flex-col items-center text-center">
      <div className="mb-4 flex items-center justify-center h-16">{icon}</div>
      <h3 className="text-lg md:text-xl font-bold mb-2">{title}</h3>
      <p className="text-sm md:text-base">{description}</p>
    </div>
  );
};

export default LandingPage;
